import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

              <h3 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose</span>
                Coinsclone For Enjin Clone Script?</h3>
              <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img width="507px" height="481px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/why-choose-coinsclone-for-enjin.webp" alt="Why Choose our Enjin Clone Script" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">When people search for a trustworthy <span className='learn-more'><a href='https://www.coinsclone.com/nft-marketplace-development/'> NFT Marketplace Development Company</a></span>, they naturally end up with Coinsclone. We serve the finest high-quality clone script services for all emerging startups at a budget-friendly cost. We are one of the early birds in the blockchain industry and have years of experience in offering crypto-based services with impeccable outcomes. Our Enjin clone script is well devised and multi-tested by skilled quality analysts. We have a handful of seasoned blockchain developers, UI/UX designers, and Android/IOS app developers.
              </p>
              <p className="pharagraph mb-0">Our premium Enjin clone script is specifically built for budding aspirants who want to kickstart a flourishing NFT Marketplace business in a cost-effective way. Our team will use the latest technology tools that are available in the Ethereum network to build an NFT Marketplace like Enjin. We believe in teamwork with coordination and sincerity to meet the expectations of every clientele that comes to our doorway. We prioritize quality over everything else. So, without further ado, buy our premium Enjin clone script and guarantee that our script will be worth the investment. Besides, by purchasing our script, you will get 24/7 technical assistance from our team of experts!
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose