import React from 'react'

import ButtonComponent from '../ButtonComponent/ButtonComponent'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Top Features</span> of </span>
                Our Enjin Clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Ads-Free</h4>
                <p className="pharagraph">One of the unique features our Enjin clone script offers is the ads free experience to the customers which is more welcomed by all the participants. Having this feature prohibits annoying ads from popping up at regular intervals.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/ads-free.webp" alt="Ads-Free" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/seamless-trading.webp" alt="Seamless Trading" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">Seamless Trading</h4>
                <p className="pharagraph">Our Enjin clone script makes sure to provide a flawless experience to the participants from step one so that they are connected to the system in a hassle-free manner!
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Responsible Design</h4>
                <p className="pharagraph">Our Enjin clone script is designed in an optimal method where it can be functional in multi operating frameworks like desktops, smartphones, and tablets in various resolutions including 4K.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/responsible-design.webp" alt="Responsible Design" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/nfts-for-everyone.webp" alt="NFTs For Everyone" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">NFTs For Everyone</h4>
                <p className="pharagraph">The use cases of our Enjin clone script are open to all industries like arts, sports, music, real-world, digital collectibles, gaming, etc.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>

          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Prompt Search Results</h4>
                <p className="pharagraph">Get a swift response on your filter and search option on our Enjin clone script. Our script is built in a way where it can feed the data in milliseconds in terms of transaction speed.
                </p>
                <div className="text-left ">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/enjin-new/prompt-search-results.webp" alt="Prompt Search Results" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures