import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          
          <h4 className="heading-h2"><span className="heading-h3 bluecolor">FAQ</span>
            Frequently Asked Questions</h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                How fast can I start making money with the Enjin clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">As soon as your users start trading using your NFT Marketplace, you can start making money. When you start making money or how much money you make depends on the number of users you can get to your NFT Marketplace. It is like, the Enjin clone script can build you the train and also make the train run from one station to another, but you will need to sell tickets and get passengers to make money.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                What are the Features of the White Label Enjin Clone Script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Our clone script is built with unique features such as prompt search results, creator ratings, royalty payments, a modern admin dashboard, multi-lingual assistance, and more.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                Is it very expensive to build an NFT Marketplace using the Enjin clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">No, our premium white label Enjin clone script comes at an affordable cost but at the same time, it has a very high standard. Additionally, we can offer you a complete package at a budget-friendly cost to build your entire NFT Marketplace using our Enjin clone script. This is a special offer that we make to our clients who buy their Enjin clone script from us.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                What are the advantages of using the Enjin clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">This script has some major highlights. Such as built on a secure Ethereum blockchain, customizable, cost-effective, highly responsive, instant deployment, comes with outstanding features and is 100% error-free.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                Once I purchase the Enjin clone script, how much time does it take to build the marketplace?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">It may take anywhere between 7 to 10 days and it also depends on the number of features you want in your NFT Marketplace. Although, there are some standard features that must be present in the NFT Marketplace. But if you need some unique and customized features, then it may take more time to craft the Enjin clone script as per your requirements.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection